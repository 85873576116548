export default {
  colors: {
    postit: {
      red: "#EB5757",
      green: "#52BF90",
      yellow: "#F2C94C",
      blue: "#5D93E1",
      grey: "#D9D9D9",
    },
    primary: "#5D93E1", // Primary color for main elements
    secondary: "#52BF90", // Secondary color for accents and highlights
    tertiary: "#F2C94C", // Tertiary color for less important elements
    alert: "#EB5757", // Color for warnings or important alerts
    success: "#52BF90", // Color indicating success or positive action
    warning: "#F2C94C", // Warning color, for caution or attention needed
    background: "#ffffff", // Background color for elements
    backbackground: "#fcf9f2", // Main background color for under the body
    text: "#121212", // Primary text color
    textSecondary: "#333333", // Secondary text color, perhaps for less important text
    highlight: "#5b8bf7", // Color for highlighting or drawing attention
    // More colors as needed
    neutral: {
      light: "#F6F6F6", // Light neutral color, for backgrounds or UI elements
      medium: "#D9D9D9", // Medium neutral for borders or less emphasized elements
      dark: "#333333", // Dark color, for text or UI elements needing contrast
    },
  },

  fonts: {
    heading: "Montserrat",
    body: "Roboto, Helvetica, Arial, sans-serif",
  },
  sizes: {
    maxWidth: "1050px",
    maxWidthCentered: "1050px",
  },
  breakpoint: {
    phone: "40em", // 640px with default browser settings
    tablet: "56em", // 896px
    desktop: "64em", // 1024px
  },
};
